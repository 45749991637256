import React from 'react';
import logo from '../images/logo512.png';
import { withRouter } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';
import SearchForm from './SearchForm';

const Header = ()=>{
    const ocultarMenu = (event)=>{
        let navbar = document.getElementsByClassName('offcanvas-collapse');
        /* console.log(navbar); */
        navbar[0].classList.remove("open");
    }
    return(
    <>
        <nav className="navbar navbar-expand-md fixed-top navbar-dark bg-dark">
            <Link to="/" className="navbar-brand" onClick={ocultarMenu}>
                <img src={logo} width="30" height="30" alt="" loading="lazy" />
            </Link>
            <button className="navbar-toggler p-0 border-0" type="button" data-toggle="offcanvas">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
                <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li className="nav-item">
                        <NavLink to="/" className="nav-link" activeClassName="active" onClick={ocultarMenu} exact >Home</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/categorias" className="nav-link" activeClassName="active" onClick={ocultarMenu}>Categorías</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/instalar-app" className="nav-link" activeClassName="active" onClick={ocultarMenu}>Instalar App</NavLink>
                    </li>
                </ul>
                <SearchForm />
            </div>
        </nav>

    </>




        /* <div className="navbar navbar-expand-lg sticky-top navbar-dark bg-dark">
            <Link to="/" className="navbar-brand">
                <img src={logo} width="30" height="30" alt="" loading="lazy" />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li className="nav-item">
                        <NavLink to="/" className="nav-link" activeClassName="active" exact>Home</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/categorias" className="nav-link" activeClassName="active">Categorías</NavLink>
                    </li>
                </ul>
                <SearchForm />
            </div>
        </div> */



        
            
        
        
    )
}

export default withRouter (Header);