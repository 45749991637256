import { useEffect, useState } from 'react';
import Api from '../utils/api.js';

const api = Api;

const useGetProductsByTag = (tagId, page)=>{
const [productsByTag, getProductsByTag] = useState([]);
const [cantidadDePaginas, getCantidadDePaginas] = useState(1);
useEffect(()=>{
    getProductsByTag([]); // 1. Cada vez que pedís por los productos vaciás el array...
    api.get("products",{
        per_page: 12,
        page: page,
        tag: tagId,
    })
    .then((info) => {
        getProductsByTag(info.data); // 2. ...y aquí es donde volvés a llenar el array con los productos que te llegan
        getCantidadDePaginas(info.headers['x-wp-totalpages']);
        //console.log(data.headers);
    })
}, [tagId, page]);
return {
    productsByTag,
    pageCount: cantidadDePaginas,
}
}

export default useGetProductsByTag;