import React from 'react';
import useGetProductsByTag from '../hooks/useGetProductsByTag';
import { useParams, useLocation } from 'react-router-dom';
import Products from './Products';
import Product from './Product';
import Cargando from './Cargando';
import {Link} from 'react-router-dom';
import Pagination from './Pagination';

const Category = ()=>{
    //const slug = useParams().slug;
    const idParam = useParams().id;
    /* const pagina = useParams().page; */
    const urlActual = useLocation().pathname;

    const pagina = new URLSearchParams(useLocation().search);
    
    // console.log(urlActual);

    const productsByTag = useGetProductsByTag(idParam, pagina.get('page')).productsByTag;
    const pageCount = useGetProductsByTag(idParam, pagina.get('page')).pageCount;
    //console.log(pageCount);
    // console.log(productsByTag);
    return(
        <div>
            <div className="container mt-4"><Link to="/categorias"><i className="fas fa-arrow-left"></i> Volver a categorías</Link></div>

            {/* Aquí le tiramos un conditional render dependiendo si el array está o no vació */}
            { productsByTag.length === 0 && <Cargando />}
            { productsByTag.length > 0 && (
                <Products>
                    { productsByTag.map(item => <Product key={item.id} {...item} />)}
                </Products>
            )}
            
            <Pagination baseurl={`${urlActual}/`} pagina={pagina.get('page')} productos={productsByTag} pageCount={pageCount}/>
        </div>
    )
}

export default Category;