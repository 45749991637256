import { useEffect, useState } from 'react';
import Api from '../utils/api.js';

const api = Api;

const useGetSearchProducts = (page, keyword)=>{
    const [products, getProducts] = useState([]);
    const [cantidadDePaginas, getCantidadDePaginas] = useState(0);
    /* const [isLoading, setIsLoading] = useState(true); */
    /* console.log(cantidadDePaginas); */
    useEffect(()=>{
        getProducts([]); // 1. Cada vez que pedís por los productos vaciás el array...
        api.get("products",{
            per_page: 12,
            page: page,
            search: keyword
        })
            .then((info) => {
                getProducts(info.data); // 2. ...y aquí es donde volvés a llenar el array con los productos que te llegan
                getCantidadDePaginas(info.headers['x-wp-totalpages']);
                /* setIsLoading(false); */
                /* console.log(info); */
            })
        return {};
    }, [page, keyword]);

    return {
        products,
        /* isLoading, */
        pageCount: cantidadDePaginas,
        keyword,
    }
}

export default useGetSearchProducts;