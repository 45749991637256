import React from 'react';
import Product from './Product';
import useGetSearchProducts from '../hooks/useGetSearchProducts';
import Cargando from '../components/Cargando';
import { useHistory, useLocation } from 'react-router-dom';
import Pagination from './Pagination';
import Products from './Products';
import {Link} from 'react-router-dom';

const SearchResults = () =>{
    const pagina = new URLSearchParams(useHistory().location.search);
    /* console.log(pagina); */

    /* const history = useHistory();
    const path = history.location.pathname.split('/');
    const keyword = path[2]; */
    /* console.log(keyword); */

    const location = useLocation();
    const path = location.pathname.split('/');
    const keyword = path[2];

    /* console.log(rutaSplit[2]); */

    /* const [keyword, setKeyword] = useState(""); */
    /* setKeyword(key); */
    /* console.log(keyword); */
    const productos = useGetSearchProducts(pagina.get('page'), keyword).products;
    const pageCount = useGetSearchProducts(pagina.get('page'), keyword).pageCount;
/*     console.log("PageCount", pageCount);
    console.log("ProductLength", productos.length); */
    return(
        <div>
            <div className="container mt-4">
                <Link to="/"><i className="fas fa-arrow-left"></i> Volver al catálogo</Link>
            </div>
            <h1 className="text-center mt-4 mb-4">Resultados de busqueda</h1>

            {/* Aquí le tiramos un conditional render dependiendo si el array está o no vació */}
            
            { productos.length === 0 && <Cargando /> }

            {productos.length > 0 && (
                <Products>
                    { productos.map(item => <Product key={item.id} {...item} />)}
                </Products>
            )}
            
            <Pagination baseurl="/" pagina={pagina.get('page')} productos={productos} pageCount={pageCount}/>
                  
        </div>
    )
}

export default SearchResults;