import { useEffect, useState } from 'react';
import Api from '../utils/api.js';

const api = Api;

const useGetCategories = ()=>{
const [categories, getCategories] = useState({
    'categories': [],
}); 
useEffect(()=>{
    api.get("products/tags",{
        per_page: 50,
    })
        .then((info) => { 
            getCategories(info);
            //console.log(info.data);
        })
}, []);
return categories;
}

export default useGetCategories;