import React from 'react';
import Product from './Product';
import useGetProducts from '../hooks/useGetProducts';
import Cargando from '../components/Cargando';
import { useLocation } from 'react-router-dom';
import Pagination from './Pagination';
import Products from './Products';



import PwaInstallPopupIOS from 'react-pwa-install-ios';
import AddToHomeIOSContent from './AddToHomeIOSContent';

const Home = ()=>{
    // const pagina= useParams().page;
    /* const pagina = new URLSearchParams(useHistory().location.search); */
    const pagina = new URLSearchParams(useLocation().search);
    /* console.log(pagina); */

    const productos = useGetProducts(pagina.get('page')).products;
    const pageCount = useGetProducts().pageCount;

    return(
        <>
            <PwaInstallPopupIOS>
                <AddToHomeIOSContent />
            </PwaInstallPopupIOS>
            <h1 className="text-center mt-4 mb-4">Catálogo de productos</h1>
            {/* Aquí le tiramos un conditional render dependiendo si el array está o no vació */}
            { productos.length === 0 && <Cargando /> }
            { productos.length > 0 && (
                <Products>
                    { productos.map(item => <Product key={item.id} {...item} />) }
                </Products>
            )}
            
            <Pagination baseurl="/" pagina={pagina.get('page')} productos={productos} pageCount={pageCount}/>
                
        </>    
    )
    
}

export default Home;