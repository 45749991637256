import React from 'react';
import { Link} from 'react-router-dom';

const Pagination = (props)=>{
    
    const pagina = props.pagina;
    const productos = props.productos;
    /* const history = useHistory(); */

   /*  const path = history.location.pathname; */
    
    return(
        <div className="paginacion text-center mb-4 mt-4">
            {pagina > 1 ? (
                <Link to={`?page=${Number(pagina)-1}`}>
                    <button className="btn btn-primary"><i className="fas fa-chevron-left"></i></button>
                </Link>
            ):null
            }

            {
                pagina ? (
                    <span className="font-weight-bold pr-4 pl-4">Página {pagina} - {props.pageCount}</span>
                ) :
                (
                    <span className="font-weight-bold pr-4 pl-4">Página 1 - {props.pageCount}</span>
                )
            }
                
            {props.pageCount !== 1 ? (
                productos.length < 12 ? null : (
                    pagina ? (
                        <Link to={`?page=${Number(pagina)+1}`}>
                            <button className="btn btn-primary"><i className="fas fa-chevron-right"></i></button>
                        </Link>
                    ):(
                        <Link to={`?page=2`}>
                            <button className="btn btn-primary"><i className="fas fa-chevron-right"></i></button>
                        </Link>
                    )
                ) 
            ) : null
            }
            
        </div>
    ) //return
} //cierra const

export default Pagination;