import React from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
//import useGetProducts from '../hooks/useGetProducts';
import useGetProduct from '../hooks/useGetProduct';
import Cargando from './Cargando';
import Whatsapp from './Whatsapp';

const ProductDetails = ()=>{
    const slug = useParams().slug;
    //console.log(useParams().slug); //obtener parametros recibidos en la url
    const idParam = useParams().id;
    //const productos = Object.values(useGetProducts());
    const producto = useGetProduct(idParam);
    //console.log('aca', producto);
    //console.log('acas', productos);
    //const slugParam = useParams().slug; //obtener slug especifico
    const pathname = useLocation().pathname;

    //console.log(idParam);

    return(
        <div className="container">
        {
            producto.length <= 1 ? <Cargando /> :(
                //producto[0].map(item=>
                   // item.slug.localeCompare(slugParam) === 0 ? //comparo que el slug por parametro sea igual al del map. Si los strings son iguales da 0
                    
                    <div className="row mt-4 mb-4" key={producto.id}>
                        <div className="container mb-4"><Link to="/"><i className="fas fa-arrow-left"></i> Volver al catálogo</Link></div>
                        <div className="col col-12 col-md-6 col-sm-6 text-center">
                            <img className="product-img mb-4" src={producto.images[0].src} alt={producto.name} />
                        </div>
                        <div className="col col-12 col-md-6 col-sm-6 text-center">
                            <h1 className="mb-4">{producto.name}</h1>
                            <p className="product-description">{producto.description.replace(/<[^>]*>?/g, '')}</p>
                            {
                                producto.price ? <p className="price">${producto.price}</p> : <p className="price">Consultar precio</p>
                            }
                            <a href={`https://riotecna.com/producto/${slug}`} target="_blank" rel="noreferrer noopener">
                                <button type="button" className="btn btn-primary btn-sm mb-4 mt-2">Ver producto en la web</button>
                            </a>
                            <Whatsapp name={producto.name}/>
                            <div>
                                <p>Compartir producto en:</p>
                                <span className="share-icon" id="share-whatsapp">
                                    <a href={`https://api.whatsapp.com/send?text=Mira%20este%20producto%20de%20Riotecna:%20https://app.riotecna.com${pathname}`} target="_blank" rel="noreferrer">
                                        <i className="fab fa-whatsapp"></i>
                                    </a>
                                </span>
                                <span className="share-icon" id="share-facebook">
                                    <a href={`https://www.facebook.com/sharer/sharer.php?u=https://app.riotecna.com${pathname}`} target="_blank" rel="noreferrer">
                                        <i className="fab fa-facebook"></i>
                                    </a>
                                </span>
                                <span className="share-icon" id="share-twitter">
                                    <a href={`https://twitter.com/intent/tweet?text=&url=https://app.riotecna.com${pathname}`} target="_blank" rel="noreferrer">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </span>
                                <span className="share-icon" id="share-mail">
                                    <a href={`mailto:?subject=Producto%20Riotecna&body=Mira%20este%20producto%20de%20Riotecna:%20http://app.riotecna.com${pathname}`} target="_blank" rel="noreferrer">
                                        <i className="fas fa-envelope"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                   // : null
                //)
            )  
        }
        </div>
    )
}

export default ProductDetails;

