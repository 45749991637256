import React from 'react';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Categories from './components/Categories';
import ProductDetails from './components/ProductDetails';
import Category from './components/Category';
import ScrollRestoration from 'react-scroll-restoration';
import SearchResults from './components/SearchResults';
import PageNotFound from './components/PageNotFound';
import AddToHomeScreenInstructions from './components/AddToHomeScreenInstructions';
//import AddToHomeScreen from '@ideasio/add-to-homescreen-react';
//<AddToHomeScreen displayPace={0} />

import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';




function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
            
      <ScrollRestoration />
      <Header />
      <Switch>
        <Route path="/categoria/:id/:slug/page/:page" exact>
          <Category />
        </Route>
        <Route path="/categoria/:id/:slug">
          <Category />
        </Route>
        <Route path="/producto/:id/:slug">
          <ProductDetails />
        </Route>
        <Route path="/categorias">
          <Categories />
        </Route>
        <Route path="/busqueda">
          <SearchResults />
        </Route>
        <Route path="/instalar-app">
          <AddToHomeScreenInstructions />
        </Route>
        <Route path="/page">
          <Home />
        </Route>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default App;
