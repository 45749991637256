import React from 'react';
import shareIcon from '../images/ic_iphone_share.png';
import androidOptions from '../images/android-options.png';

const AddToHomeScreenInstructions = ()=>{
    return(
        <div className="container">
            <h1 className="text-center mt-4 mb-4">Como instalar la app en tu dispositivo</h1>
            <div className="row">
                <div className="col col-12 col-md-6 mt-4">
                    <h2>iOS</h2>
                    <p className="mt-4">1. Ingresar a la app desde Safari</p>
                    <p>2. Click sobre el botón "Compartir" <span><img className="share-ios" src={shareIcon} alt="iOS boton compartir" /></span></p>
                    <p>3. Seleccionar 'Agregar a Inicio' y luego 'Agregar'</p>
                </div>
                <div className="col col-12 col-md-6 mt-4">
                    <h2>Android</h2>
                    <p className="mt-4">1. Ingresar a la app desde Chrome</p>
                    <p>2. Click sobre el botón opciones <span><img className="share-ios" src={androidOptions} alt="Android botón compartir" /></span></p>
                    <p>3. Seleccionar 'Agregar a la pantalla principal' y luego 'Agregar'</p>
                </div>
            </div>
        </div>
    )
}

export default AddToHomeScreenInstructions;