import React from 'react';
import {Link} from 'react-router-dom';

const CategoryList = (props)=>{
    return(
        <div>
            <h2 className="category-title mb-4">{props.name}</h2>
            <ul>
                {
                    props.list.map(item=>
                        <Link to={`/categoria/${item.id}/${item.slug}`} key={item.id}>
                            <li key={item.id}>{item.name}</li>
                        </Link>
                    )
                }
            </ul>    
        </div>
    )
        
}

export default CategoryList;