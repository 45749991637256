import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/logo512.png';
import Whatsapp from './Whatsapp';

const Product = ( {id, name, images, price, slug} )=>(
    <div className="col-sm-6 colu-products mb-4">
      <div className="product-container">
        <Link to={`/producto/${id}/${slug}/`}>
          <img className="product-img" src={images.length===0 ? Logo : images[0].src} loading="lazy" alt={name} />
          <br />
          <p className="product-name mt-4">{name}</p>
        </Link>
        {
          price ? <p className="price">${price}</p> : <p className="price">Consultar precio</p>
        }
        <Whatsapp name={name}/>
      </div>
    </div>
)

export default Product;