import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

const Api = new WooCommerceRestApi({
    url: "https://riotecna.com",
    ssl: true,
    consumerKey: 'ck_5f9fb7d8a709765c8b5e9c89cc23630644b0d5e7',
    consumerSecret: 'cs_e488323dabf3a25f2bf2ca2414aec2c63f5906b0',
    wpAPI: true,
    version: "wc/v3",
    queryStringAuth: true,
    axiosConfig: { headers: {} }
});

export default Api;
  