import React, {useState} from 'react';

import { useHistory} from 'react-router-dom';

const SearchForm = ()=>{

    const history = useHistory();
    const[busqueda, setBusqueda] = useState("");

    const setearBusqueda = (event)=>{
        event.preventDefault();
        setBusqueda(event.target.value);
    }

    const enviarBusqueda = (event)=>{
        event.preventDefault();
        // console.log("Sumbit", busqueda);
        /* console.log(busqueda); */
        /* <Redirect to={`/busqueda=${busqueda}`} /> */
        history.push(`/busqueda/${busqueda}`);
        // Con esto se deja vació el input
        let inputTag = event.target.querySelector('input');
        inputTag.value = ''; 
        let navbar = document.getElementsByClassName('offcanvas-collapse');
        /* console.log(navbar); */
        navbar[0].classList.remove("open");
    }

    return(
        <div className="searchform-container">
            <form className="form-inline my-2 my-lg-0" onSubmit={enviarBusqueda} onChange ={setearBusqueda}>
                <input className="form-control mr-sm-2" placeholder="Buscar productos" aria-label="Search" name="busqueda" />
                <button className="btn btn-outline-success my-2 my-sm-0" type="submit">BUSCAR  <i className="fas fa-search"></i></button>
            </form>
            <br/>
        </div>
    )
}

export default SearchForm;