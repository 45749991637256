import React from 'react';

const Products = ({children})=>(
    <div className="container mt-4">
        <div className="row row-eq-height row-cols-2 row-cols-sm-1 row-cols-md-3">
            {children}
        </div>
    </div>

)
export default Products;