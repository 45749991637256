import { useEffect, useState } from 'react';
import Api from '../utils/api.js';

const api = Api;

const useGetProduct = (id)=>{
const [products, getProduct] = useState([]);
useEffect(()=>{
    api.get(`products/${id}`)
        .then((info) => {
            getProduct(info.data)
        })
    return{};
}, [id]);
return products;
}

export default useGetProduct;