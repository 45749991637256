import { Link } from 'react-router-dom';

const PageNotFound = ()=>{
    return(
        <div className="container mt-4">
            <h3 className="text-center mb-4">Página no encontrada</h3>
            <div className="mb-4 text-center">
                <Link to="/"><i className="fas fa-arrow-left"></i> Volver al catálogo</Link>
            </div>
        </div>
    )
}

export default PageNotFound;