import React from 'react';
import Cargando from './Cargando';
import CategoryList from './CategoryList';

import useGetCategories from '../hooks/useGetCategories';

const Categories = ()=>{
    const categorias =  Object.values(useGetCategories());
    //console.log(categorias);
    const optimist = categorias[0].filter(name=>name.name.includes("Optimist"));
    const cadet = categorias[0].filter(name=>name.name.includes("Cadet"));
    const dospuntocuatro = categorias[0].filter(name=>name.name.includes("2.4"));
    const snipe = categorias[0].filter(name=>name.name.includes("Snipe"));
    const cuatroveinte = categorias[0].filter(name=>name.name.includes("420"));
    const veintinueveer = categorias[0].filter(name=>name.name.includes("29er"));
    const ilca = categorias[0].filter(name=>name.name.includes("ILCA"));
    
    //console.log(optimist);
    return(
        <div className="container">
            <h1 className="mt-4">Lista de categorías</h1>
            <hr/>
            <div className="text-center">
                {categorias.length <=1?<Cargando /> : null}
            </div>
            <div className="row mb-4 row-cols-1 row-cols-sm-1 row-cols-md-3 mt-4">
               
                <div className="col">
                {
                    <CategoryList name="29er" list={veintinueveer}/>    
                }  
                </div>
                <div className="col">
                {   
                    <CategoryList name="ILCA" list={ilca}/>   
                }  
                </div>
                <div className="col">
                {
                    <CategoryList name="Optimist" list={optimist} /> 
                }  
                </div>
                <div className="col">
                { 
                    <CategoryList name="Cadet" list={cadet}/>           
                }  
                </div>
                <div className="col">
                {  
                    <CategoryList name="2.4" list={dospuntocuatro}/>    
                }  
                </div>
                <div className="col">
                {
                    <CategoryList name="Snipe" list={snipe}/>   
                }  
                </div>
                <div className="col">
                {  
                    <CategoryList name="420" list={cuatroveinte}/>  
                }  
                </div>
            </div>
        </div>
        
    )
}

export default Categories;