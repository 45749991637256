import { useEffect, useState } from 'react';
import Api from '../utils/api.js';

const api = Api;

const useGetProducts = (page)=>{
    const [products, getProducts] = useState([]);
    const [cantidadDePaginas, getCantidadDePaginas] = useState(1);

    useEffect(()=>{
        // console.log(products);
        // console.log('Page:', page);
        
        getProducts([]); // 1. Cada vez que pedís por los productos vaciás el array...

        api.get("products",{
            per_page: 12,
            page: page
        })
            .then((info) => {
                // console.log(info.data);
                // getProducts(Object.values(info));
                getProducts(info.data); // 2. ...y aquí es donde volvés a llenar el array con los productos que te llegan
                getCantidadDePaginas(info.headers['x-wp-totalpages']);
                /* console.log(info); */
            })
    }, [page]);

    return {
        products,
        pageCount: cantidadDePaginas,
    }
}

export default useGetProducts;