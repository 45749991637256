import React from 'react';

import appIcon from '../images/logo512.png';
import shareIcon from '../images/ic_iphone_share.png';

const AddToHomeIOSContent = ()=>{
    return(
        <div delay={0}> 
        <div className="pwa-install-popup-ios-content">
           <div className="left">
             <img className="appIcon" src={appIcon} alt="Riotecna" />
           </div>
           <div className="right">
             <div>
             Para instalar Riotecna App en su dispositivo:
             </div>
             <div>1- Abrir Riotecna App en Safari</div>
             <div>
             2- Click en el menú compartir
               <span>
                 <img className="small" src={shareIcon} alt="iOS botón compartir" />
               </span>
             </div>
             <div>3- Seleccionar 'Agregar a Inicio' y luego 'Agregar'</div>
           </div>
         </div>
     </div>
    )
}

export default AddToHomeIOSContent;